import React, { useState, FormEvent } from "react"
import { Text, Button, Flow, FormField, Input } from "deskie-ui"
import Container from "../components/Container"
import PageWrapper from "../components/PageWrapper"
import PlanMeeting from "../components/PlanMeeting"
import styled from "styled-components"

const Header = styled(Container)`
  margin-top: -6rem;
  padding-top: 8rem;
  padding-bottom: 6rem;

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    margin: 0 0 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  background-image: url("/paths.svg");
  background-size: auto 160%;
  background-position: 100% 0%;
  background-repeat: no-repeat;
`

const Content = styled(Container)`
  padding-bottom: 3rem;

  h2 {
    color: ${props => props.theme.colors.primary};
  }

  form {
    margin-top: 1rem;

    @media screen and (max-width: 600px) {
      & > .flow {
        flex-direction: column;

        & > * + * {
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }

  .button {
    margin-top: 2rem;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`

function Register() {
  const [name, setName] = useState<string>("")
  const [companyName, setCompanyName] = useState<string>("")

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    // Do not used build in Navigate function because a page refresh is necessary
    const plan =
      new URLSearchParams(window.location.search).get("plan") || "Premium"
    const _name = encodeURIComponent(name)
    const _companyName = encodeURIComponent(companyName)
    window.location.href = `/login/az?returnUrl=/welcome?name=${_name}&companyName=${_companyName}&plan=${plan}`
  }

  return (
    <>
      <Header>
        <Text h1>Aan de slag met Deskie. Nice!</Text>
      </Header>
      <PageWrapper>
        <Content>
          <Text p>
            Meld je aan en registreer je met jouw Microsoft 365 account. Daarna
            kun je direct met Deskie aan de slag!
          </Text>

          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Flow direction="row" gap="1.5rem">
              <FormField style={{ width: "100%" }} label="Naam*">
                <Input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
              </FormField>
              <FormField style={{ width: "100%" }} label="Bedrijfsnaam*">
                <Input
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                  required
                />
              </FormField>
            </Flow>
            <Button
              type="submit"
              icon={
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"
                  ></path>
                </svg>
              }
            >
              Registreer met Microsoft
            </Button>
          </form>
        </Content>
        <PlanMeeting />
      </PageWrapper>
    </>
  )
}

export default Register
